export function productionEnv() {
  return window.env.env === "production";
}

export function currentPath({withoutParams} = {withoutParams: true}): string {
  const path = window.location.pathname + window.location.search
  if (withoutParams) {
    return path.split("?")[0]
  }
  else {
    return path
  }
}

export function attempt(riskyOperation: () => void) {
  try {
    riskyOperation()
  } catch(error) {
    handleError(error);
  }
}

// Example usage: set("analytics.email_conversion", true)
export function set(object, path: string, value: any): void {
    var schema = object;  // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }

    schema[pList[len-1]] = value;
}

export function dig(path: string, obj: object): any {
  const pathArray = path.split(".")
  return pathArray.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, obj);
}


function handleError(error: Error) {
  if (productionEnv()) {
    window.Rollbar.error(error);
  } else {
    console.error("Attempt logged:", error)
  }
}

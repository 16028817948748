export function listCookies(): Array<Cookie> {
  return document.cookie.split(";").
    map((cookie) => {
      // Trim leading white spaces
      while (cookie.charAt(0) == " ") cookie = cookie.substring(1, cookie.length)
      const [name, value] = cookie.split("=")
      return {
        name,
        value
      }
    })
}

export function readCookie(name: AvailableCookies): string | null {
  const cookie: Cookie = listCookies().
    find(cookie => cookie.name == name)

  return cookie && cookie.value
}

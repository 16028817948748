// Dependency: A `.hidden` class should exist in CSS
export function hide(htmlSelector: string): void {
  const els = document.querySelectorAll<HTMLElement>(htmlSelector)
  els.forEach(el => {
    el.classList.add("hidden")
  })
}

// Dependency: A `.hidden` class should exist in CSS
export function unhide(htmlSelector: string): void {
  const els = document.querySelectorAll<HTMLElement>(htmlSelector)
  els.forEach(el => {
    el.classList.remove("hidden")
  })
}

export function toggleHidden(htmlSelector: string): void {
  const el = document.querySelector<HTMLElement>(htmlSelector)
  if (el.classList.contains("hidden")) {
    unhide(htmlSelector)
  } else {
    hide(htmlSelector)
  }
}

export function onLoad(callback: () => void) {
  window.addEventListener('turbolinks:load', callback)
}

// Argument has form "data-x" - i.e. do not exclude the data part
export function getDataAttribute(dataAttr: string): string | null {

  if (! dataAttr.startsWith("data-")) {
    throw "Must start with 'data-'";
  }

  // We assume the Wistia embed has a data attribute containing the wistia-video-id
  const el = document.querySelector(`[${dataAttr}]`)
  if (! el) {
    return null;
  }

  return el.getAttribute(dataAttr);
}

export function addHiddenField(form: HTMLFormElement, name: string, value: string): void {
  let input = document.createElement("input")
  input.setAttribute("type", "hidden")
  input.setAttribute("name", name)
  input.setAttribute("value", value)
  form.appendChild(input)
}

import hljs from 'highlight.js';
import { getDataAttribute } from "domLibrary";

export function setupCodeHighlighting() {
  document.querySelectorAll('pre code').forEach((block: HTMLElement) => {
    hljs.highlightBlock(block);
  });

  const transcript = document.getElementById("transcript");
  if (transcript) {
    const color = getDataAttribute('data-code-color');
    transcript.querySelectorAll("code").
      forEach(node =>
        node.className += `text-${color} px-1 bg-light-black`
      );
  }
}

import { localLog } from "instrumentation"
import { dig, attempt, currentPath, productionEnv, set } from "library"

type TrackingEvent = "play_video" | "email_list_signup" |
  "viewed_items" | "add_to_cart" | "purchase" | "conversion";
type TrackingProvider = "twitter" | "adwords" | "analytics" | "facebook";

export function trackEvent(trackingEvent: TrackingEvent, data: any = {}): void {
  localLog(`Fired tracking event: ${trackingEvent}`);

  // I need to create each event type on Twitter first.
  trackAnalyticsEvent(trackingEvent);


  // We don't want to confuse the advertising platforms with low value
  // events, like video plays
  if (trackingEvent == "email_list_signup") {
    trackAdwordsConversion(trackingEvent);
    trackFacebookConversion(trackingEvent);
    trackTwitterConversion(trackingEvent);
  }
}

export function setupPageBasedEvents(): void {
  switch (currentPath()) {
    case "/plans": {
      trackEvent("viewed_items", {
          "items": [
            {
              "id": "month",
              "name": "SemicolonAndSons month"
            },
            {
              "id": "year",
              "name": "SemicolonAndSons year"
            }
          ]
        }
      );
      break;
    }
  case "/checkout": {
    trackEvent("add_to_cart", {
      "items": [
        window.env.trackingData['analytics_ecommerce_items']
      ]
    });
    break;
  }
  case "/checkout/success": {
    const keyPresentAfterPurchase = "analytics_ecommerce_purchase_data"
    let purchaseData = dig('trackingData.keyPresentAfterPurchase', window.env)
    if (purchaseData) {
      trackEvent("purchase", purchaseData)
    }
    else {
      localLog(`Expected key ${keyPresentAfterPurchase} but was null`)
    }
    break;
  }
}
}

function trackFacebookConversion(trackingEvent: TrackingEvent) {
  doInProduction("facebook", trackingEvent, () => window.fbq('track', trackingEvent))
}

function trackAnalyticsEvent(trackingEvent: TrackingEvent) {
  doInProduction("analytics", trackingEvent, () => window.gtag('event', trackingEvent))
}

function trackAdwordsConversion(trackingEvent: TrackingEvent): void {
  doInProduction("adwords",
    trackingEvent,
    () => trackEvent('conversion', {
      'send_to': `${window.env.adwordsTrackingId}/zbmrCK2B4tcBEMbljdgD`,
    })
  )
}

function trackTwitterConversion(trackingEvent: TrackingEvent): void {
  if (trackingEvent == "email_list_signup") {
    const emailListSignupToken = 'o48xy'
    doInProduction("twitter",
      trackingEvent,
      () => window.twttr.conversion.trackPid(emailListSignupToken, { tw_sale_amount: 0, tw_order_quantity: 0 })
    )
  }
}

function doInProduction(trackingProvider: TrackingProvider, trackingEvent: TrackingEvent, track: () => any) {
  if (! productionEnv()) {
    set(
      window.SemicolonAndSons.eventsTracked,
      `${trackingProvider}.${trackingEvent}`,
      true
    )
    console.log(`Would log ${trackingProvider} ${trackingEvent} if production`);
    return;
  };

  attempt(track);
}


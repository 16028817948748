import { getDataAttribute, unhide } from "./domLibrary"
import {loadStripe } from '@stripe/stripe-js';

export async function setupStripeSubscriptions() {
  const stripePublicKey = getDataAttribute("data-stripe-public-key")
  if (! stripePublicKey) return;

  const stripe = await loadStripe(stripePublicKey);

  const checkoutButton = document.getElementById('checkout_button');
  const stripeSessionId = getDataAttribute("data-stripe-session-id");

  checkoutButton.addEventListener('click', function() {
    stripe.redirectToCheckout({
      sessionId: stripeSessionId
    }).then(function (result) {
      showStripeError(result.error)
    });
  });
}


function showStripeError(error) {
  const selector = "#error_message_pane"
  console.error("Stripe Error", error)

  const errorMessage = `${error["code"]}: ${error["message"]}`
  document.querySelector(selector).innerHTML = errorMessage
  unhide(selector)
}

import { onLoad, toggleHidden, hide } from "domLibrary"
import { trackEvent}  from "tracking"

function onAjaxComplete(selector: string, callback: (htmlId: string, event: CustomEvent) => void) {
  document.querySelector(selector)?.
    addEventListener("ajax:complete", (event) =>
      callback(selector, <CustomEvent>event)
    )
}

function handleMailingListSignup(htmlId: string, event: CustomEvent): void {
  console.log("event", event)
  const result= document.createElement('h1')
  result.id =
    "interactivity_added_to_list"
  result.className += "text-xl font-sans2 inline-block rounded bg-green p-4 mt-4 text-black"

  // Check if 200 status code
  if (event.detail[0].status.toString()[0] == "2") {
    trackEvent("email_list_signup")
    result.innerHTML = `Success! You've been added to the list. You can also
     follow on other platforms.
      <p class="paragraph">
        Twitter: Follow <a class="underline"
        href="${window.env.twitter_url}">${window.env.twitter_handle}</a>
      </p>
      <p class="paragraph">
        YouTube? Subscribe to
        <a href="https://www.youtube.com/channel/UC17mJJnvzAa_e9qQqLIfIeQ"
         class="underline"
        > Semicolon&Sons YT</a>
      </p>
      `
    document.querySelector(htmlId).replaceWith(result)
  } else if (event.detail[0].status.toString() === "403") {
    result.innerText = "Our system suspected bot activity. If wrong, email semicolonandsons@gmail.com"
    document.querySelector(htmlId).appendChild(result)
  } else {
    result.innerText = "ERROR: Invalid or missing email"
    document.querySelector(htmlId).appendChild(result)
  }
}

export function setupMailingLists() {
  onAjaxComplete("#mailing_list_form-body", handleMailingListSignup)
}

export function setupMobileNavigation() {
  const hamburger = document.getElementById("mobile_hamburger")

  hamburger.addEventListener("click", () => toggleHidden(
    "#mobile_navigation_overlay"
  ))

  const x = document.getElementById("close_mobile_navigation")
  x.addEventListener("click", () => hide(
    "#mobile_navigation_overlay"
  ))
}

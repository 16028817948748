import { readCookie} from "cookies"
import { localLog } from "instrumentation"
import { trackEvent } from "tracking"

export default {
  eventsTracked: {
  },

  loggedIn(): boolean {
    return !!readCookie("user_id")
  },

  videoReady(video: WistiaVideo) {
    // All the bindings must happen before any early returns
    video.bind("conversion", function(conversionType, email, firstName, lastName) {
      trackEvent("email_list_signup")
      this.createUserAccount(email)
    }.bind(this))

    video.bind("play", function() {
      trackEvent('play_video')
    }.bind(this))

    if (!this.loggedIn()) {
      return
    }

    this.getUserInformation().
      then((userInformation: UserInformation) => {
        localLog(`Setting email to ${userInformation.email}`)

        // Set user email for Wistia view
        video.email(userInformation.email)
      })
  },

  getUserInformation(): Promise<UserInformation> {
    return fetch("/api/user_information")
    .then(res => res.json())
    .catch(err => { throw err });
  },

  createUserAccount(email: string) {
    const data = {user: {email, interests: this.interests()}}
    return fetch("/email_subscribers", {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      // body data type must match "Content-Type" header
      body: JSON.stringify(data)
    }).then(() => {
      return localLog("Saved email to backend")
    })
  },

  interests(): string[] {
    return JSON.parse(window.env.interests);
  },
}

// All my custom front-end code should be in TypeScript, not JavaScript

import SemicolonAndSons from "semicolonAndSons"
import { setupMailingLists, setupMobileNavigation } from "interactivity"
import { setupPageBasedEvents } from "tracking"
import { setupStripeSubscriptions } from "stripeIntegration"
import { setupRollbar } from "rollbar"
import { setupCodeHighlighting } from "codeHighlighting"
import { onLoad, getDataAttribute } from "domLibrary";

// Setup the SemicolonAndSons client
onLoad(() => {
  // Setup the SemicolonAndSons client
  window.SemicolonAndSons = SemicolonAndSons
  setupWistia()
  setupMailingLists()
  setupStripeSubscriptions()
  setupPageBasedEvents()
  setupCodeHighlighting()
  setupRollbar()
  setupMobileNavigation()
})

function setupWistia() {
  // Wistia expects the following available
  window._wq = window._wq || [];

  // We assume the Wistia embed has a data attribute containing
  // `wistia-video-id`
  const wistiaId = getDataAttribute("data-wistia-video-id")

  // Restrict to pages that have Wistia videos
  if (wistiaId) {
     window._wq.push({
       id: wistiaId,
       onReady: (video) => { window.SemicolonAndSons.videoReady(video) }
     })
  }
}
